import tw, { styled } from 'twin.macro'

export const Wrapper = tw.main`
 w-full 
`
//section 1
export const Section1 = styled.section`
  ${tw`  w-full  mx-auto flex flex-col gap-10 pb-14  px-7 items-start justify-center 
  lg:min-height[calc(100vh + 5rem)] lg:flex-row lg:items-center lg:gap-16  lg:py-16 lg:pl-16 lg:pr-24 border-b-[10px] border-[#EAEAEA]`}
  img {
    ${tw`mt-28 lg:mt-0`}
  }
`

export const Article = styled.article`
  ${tw`w-full lg:w-[45%] flex flex-col gap-4 justify-center items-start text-xl`};
  span {
    ${tw`uppercase text-yellow text-lg`}
  }
  button {
    margin-top: 20px;
  }
`

//Section 2
export const Section2 = tw.section`
 w-full mx-auto flex flex-col gap-8 mt-14 pb-14 px-7 items-center justify-center
 lg:gap-16   lg:px-[8.75rem] border-b-[10px] border-[#EAEAEA] lg:mt-32 lg:pb-32
`

export const Grid = tw.div`
 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 place-items-center 
`

//section 4
export const Section4 = tw.section`
 w-full mx-auto flex flex-col gap-32  pt-8 pb-14  items-center justify-center
  md:gap-x-28  border-b-[10px] border-[#EAEAEA] lg:mt-6 lg:pb-32
`

export const Columns = tw.article`
 w-full gap-14 lg:gap-20  px-7 md:w-[92%] md:px-0 flex flex-col  md:flex-row justify-between 
`

export const Column = styled.div(({ withBorder }) => [
  tw`flex flex-col gap-8 md:w-[50%]`,
  withBorder && tw`md:[&>h2]:pr-10`,
])

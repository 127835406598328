import tw, { styled } from 'twin.macro'

export const Wrapper = styled.div(({ withBorder }) => [
  tw`flex flex-col gap-5 justify-center items-start pl-0`,
  withBorder && tw`pr-0 border-0 md:pr-20 md:border-r border-[#E0E0E0]`,
])

export const Container = tw.div`
 flex flex-col gap-1.5 items-start
`

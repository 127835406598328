import tw, { styled } from 'twin.macro'

export const status = {
  accepted: tw`text-lightGreen bg-[rgba(70, 217, 182, 0.12)]`,
  pending: tw`text-yellow bg-[rgba(239, 156, 5, 0.12)]`,
  rejected: tw`text-darkPink  bg-[rgba(229, 69, 127, 0.12)]`,
}
export const Wrapper = styled.p(() => [
  tw`inline-flex justify-center items-center relative py-1.5  pl-[22px] pr-2 
     uppercase align-middle text-base font-medium rounded-full
     before:content['●'] before:absolute before:left-2 before:text-lg`,
  ({ value }) => status[value],
])

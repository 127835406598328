import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
//components
import { Menu } from '@headlessui/react'
//styles
import { Icon, Name, Text } from './ProfileDropdown.styles'
import { theme } from 'twin.macro'
//Icons
import {
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

function ProfileDropdown({ name }) {
  const { t } = useTranslation()
  return (
    <Menu as="div" className="profile-dropdown">
      <Menu.Button className="profile-button">
        <Icon>
          <ChevronDownIcon width={20} height={20} color="white" />
        </Icon>
        <Name>{name}</Name>
      </Menu.Button>
      <Menu.Items className="profile-list">
        <Menu.Item>
          <Link to="/auth/logout" className="profile-item">
            <Icon>
              <ArrowRightOnRectangleIcon
                width={32}
                height={32}
                color={theme`colors.darkPink`}
              />
            </Icon>
            <Text>{t('signOut')}</Text>
          </Link>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}

ProfileDropdown.propTypes = {
  name: PropTypes.string,
}

export default ProfileDropdown

import tw, { styled } from 'twin.macro'

export const StyledBtn = styled.button(
  ({ isTransparent, color, isRounded, borderColor, disabled, bgColor }) => [
    tw`bg-white inline-flex justify-center items-center gap-4 rounded-full px-6 py-3
        whitespace-nowrap flex-nowrap text-base md:text-xl  font-medium border-0 capitalize`,
    isTransparent && tw`background[inherit] p-0`,
    isRounded && tw`p-0 w-11 h-11 border`,
    disabled && tw`opacity-40 cursor[none] `,
    color && `color: ${color};`,
    bgColor && `background: ${bgColor};`,
    borderColor && `border: 1px solid ${borderColor};`,
  ],
)

export const StyledIcon = styled.span(({ isLeft }) => [
  isLeft && tw`order-first`,
])

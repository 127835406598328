import { CheckIcon } from '@heroicons/react/24/solid'
import Button from 'components/Button'
import Input from 'components/Input'
import { useTranslation } from 'react-i18next'

import { theme } from 'twin.macro'
import { Grid, Header, Title, Wrapper } from './Form.styles'

function Form({ onSubmit, handleChange, state, isLoading }) {
  const { t } = useTranslation()
  return (
    <Wrapper onSubmit={onSubmit}>
      <Header>
        <Title>{t('addActivity')}</Title>
        <Button
          label={isLoading ? t('adding') + '...' : t('done')}
          bgColor={theme`colors.brightBlue`}
          Icon={CheckIcon}
          color="white"
        />
      </Header>
      <Grid>
        <Input
          type="date"
          label={t('checkIn')}
          name="checkIn"
          required={true}
          placeholderr={t('selectDate')}
          value={state?.checkIn}
          onChange={handleChange}
        />
        <Input
          type="date"
          label={t('checkOut')}
          name="checkOut"
          required={true}
          placeholder={t('selectDate')}
          value={state?.checkOut}
          onChange={handleChange}
        />
        <Input
          type="text"
          label={t('price')}
          name="averagePrice"
          required={true}
          placeholder="MAD ----"
          value={state?.price}
          onChange={handleChange}
        />
        <Input
          type="text"
          label={t('invoiceNum')}
          name="invoiceNumber"
          required={true}
          placeholder={t('typeInvoice')}
          value={state?.invoiceNum}
          onChange={handleChange}
        />
        <Input
          type="text"
          label="hotel"
          name="hotelName"
          required={true}
          placeholder={t('hotelName')}
          value={state?.hotelName}
          onChange={handleChange}
        />
      </Grid>
    </Wrapper>
  )
}

export default Form

import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import Input from 'components/Input'
import { updateProfile } from '../auth/services'
import useUser from 'hooks/useUser'
import { Container, Ellipse, Form, Submit, Title, Wrapper } from '../auth/Style'
import ellipse from 'assets/icons/ellipse.svg'

export default function CompleteProfile() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useUser(state => state.user)
  const setUser = useUser(state => state.setUser)

  const [name, setName] = useState({
    firstName: '',
    lastName: '',
  })

  useEffect(() => {
    if (user?.user?.name && user?.user?.firstname) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line
  }, [user])

  const handleChange = e => {
    setName(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  //Add activity
  const updateProfileMutaion = useMutation(
    () => updateProfile(name.lastName, name.firstName, user.access.token),
    {
      onSuccess: newUser => {
        let copyUser = { ...user }
        copyUser.user = { ...newUser }
        setUser(copyUser)
      },
    },
  )

  const handleSubmit = async e => {
    e.preventDefault()
    updateProfileMutaion.mutate()
  }

  return (
    <Wrapper>
      <Ellipse src={ellipse} position="top" />
      <Ellipse src={ellipse} position="bottom" />
      <Container>
        <Title>
          complete <span>{t('your')} profile </span>
        </Title>
        <Form onSubmit={handleSubmit}>
          <Input
            label={t('firstName')}
            type="text"
            name="firstName"
            onChange={handleChange}
          />
          <Input
            label={t('lastName')}
            type="text"
            name="lastName"
            onChange={handleChange}
          />
          <div>
            <Submit type="submit" disabled={!name}>
              let's go
            </Submit>
          </div>
        </Form>
      </Container>
    </Wrapper>
  )
}

const Prize = ({ color, width = 80, height = 80 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M76.25 38.75H70.5175L70.84 38.4275C71.91 37.3575 72.5 35.9337 72.5 34.4187C72.5 31.2925 69.9575 28.75 66.8312 28.75C65.3162 28.75 63.8925 29.34 62.8225 30.41L55 38.2325L47.1775 30.41C46.1075 29.34 44.6838 28.75 43.1687 28.75C40.0425 28.75 37.5 31.2925 37.5 34.4187C37.5 35.9337 38.09 37.3575 39.16 38.4275L39.4825 38.75H33.75C33.0588 38.75 32.5 39.3088 32.5 40V47.5V76.25C32.5 76.9412 33.0588 77.5 33.75 77.5H76.25C76.9412 77.5 77.5 76.9412 77.5 76.25V47.5V40C77.5 39.3088 76.9412 38.75 76.25 38.75ZM75 46.25H60V41.25H75V46.25ZM52.5 46.25V41.25H57.5V46.25H52.5ZM57.5 48.75V75H52.5V48.75H57.5ZM64.59 32.1775C65.1875 31.58 65.9837 31.25 66.8312 31.25C68.5775 31.25 70 32.6725 70 34.4187C70 35.2662 69.67 36.0612 69.0725 36.66L66.9825 38.75H58.0175L64.59 32.1775ZM40.9275 36.66C40.33 36.0625 40 35.2662 40 34.4187C40 32.6725 41.4225 31.25 43.1687 31.25C44.0162 31.25 44.8112 31.58 45.41 32.1775L51.9825 38.75H43.0175L40.9275 36.66ZM50 41.25V46.25H35V41.25H50ZM35 48.75H50V75H35V48.75ZM75 75H60V48.75H75V75Z" />
      <path d="M55 18.75H37.4362C36.7912 9.68375 29.2275 2.5 20 2.5C10.35 2.5 2.5 10.35 2.5 20C2.5 29.65 10.35 37.5 20 37.5C29.2275 37.5 36.7912 30.3162 37.4362 21.25H53.75V29.4825L52.1338 27.8662L50.3662 29.6338L54.1162 33.3838C54.36 33.6275 54.68 33.75 55 33.75C55.32 33.75 55.64 33.6275 55.8838 33.3838L59.6338 29.6338L57.8662 27.8662L56.25 29.4825V20C56.25 19.3088 55.6912 18.75 55 18.75ZM20 35C11.7288 35 5 28.2712 5 20C5 11.7288 11.7288 5 20 5C28.2712 5 35 11.7288 35 20C35 28.2712 28.2712 35 20 35Z" />
      <path d="M23.915 15.0261L21.1187 9.43982C20.6937 8.59232 19.3075 8.59232 18.8825 9.43982L16.0862 15.0261L9.82375 15.9236C9.35 15.9923 8.9575 16.3223 8.81 16.7786C8.6625 17.2348 8.78875 17.7323 9.13375 18.0623L13.655 22.4073L12.5875 28.5348C12.5062 29.0036 12.6975 29.4773 13.0812 29.7586C13.465 30.0386 13.975 30.0786 14.395 29.8598L20 26.9536L25.605 29.8586C25.7862 29.9536 25.9837 29.9998 26.18 29.9998C26.4412 29.9998 26.7 29.9186 26.9187 29.7586C27.3025 29.4773 27.4937 29.0036 27.4125 28.5348L26.345 22.4073L30.8662 18.0623C31.21 17.7311 31.3362 17.2336 31.19 16.7786C31.0437 16.3236 30.65 15.9923 30.1762 15.9236L23.915 15.0261ZM24.1337 21.0661C23.835 21.3536 23.6975 21.7711 23.7687 22.1823L24.5175 26.4798L20.575 24.4373C20.3937 24.3436 20.1975 24.2973 20 24.2973C19.8025 24.2973 19.6062 24.3436 19.425 24.4373L15.4837 26.4798L16.2325 22.1823C16.3037 21.7723 16.1662 21.3548 15.8675 21.0661L12.715 18.0361L17.0887 17.4098C17.495 17.3511 17.845 17.0986 18.03 16.7311L20 12.7923L21.9712 16.7298C22.155 17.0973 22.5062 17.3498 22.9125 17.4086L27.2862 18.0348L24.1337 21.0661Z" />
      <path d="M4.71875 53.825C7.41625 54.2438 9.51 56.3387 9.92625 59.0375C10.1262 60.3187 11.2075 61.25 12.5 61.25C13.7925 61.25 14.8737 60.3188 15.0737 59.0363C15.49 56.3387 17.5837 54.2438 20.2825 53.825C21.5675 53.6238 22.5 52.5413 22.5 51.25C22.5 49.9587 21.5675 48.8762 20.2812 48.675C17.5838 48.2562 15.49 46.1613 15.0737 43.4625C14.8737 42.1813 13.7925 41.25 12.5 41.25C11.2075 41.25 10.1262 42.1812 9.92625 43.4637C9.51 46.1613 7.41625 48.2562 4.7175 48.675C3.4325 48.8762 2.5 49.9587 2.5 51.25C2.5 52.5413 3.4325 53.6238 4.71875 53.825ZM5.1025 51.1462C8.88125 50.5588 11.8138 47.6262 12.3975 43.8462C12.4062 43.7887 12.4487 43.75 12.5 43.75C12.5513 43.75 12.5938 43.7888 12.6025 43.845C13.1862 47.625 16.1175 50.5588 19.8962 51.1462C19.9462 51.1537 20 51.19 20 51.25C20 51.31 19.9463 51.3463 19.8975 51.3538C16.1188 51.9412 13.1862 54.8738 12.6025 58.6538C12.585 58.7688 12.415 58.7675 12.3975 58.655C11.8138 54.875 8.8825 51.9412 5.10375 51.3538C5.05375 51.3463 5 51.31 5 51.25C5 51.19 5.05375 51.1537 5.1025 51.1462Z" />
      <path d="M72.5 70H70V72.5H72.5V70Z" />
      <path d="M72.5 65H70V67.5H72.5V65Z" />
      <path d="M72.5 60H70V62.5H72.5V60Z" />
    </svg>
  )
}

export default Prize

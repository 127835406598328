import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import globe from 'assets/icons/globe.svg'
import { locales } from 'i18n'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { ListItem } from './LangSwitcher.styles'

function LangSwitcher() {
  const { t } = useTranslation()
  const changeLanguage = lang => {
    i18next.changeLanguage(lang)
  }

  return (
    <Menu as="div" className="lang-menu">
      <Menu.Button className="lang-button">
        <span>
          <img src={globe} />
        </span>
        {i18next.language}
        <span>
          <ChevronDownIcon width="28" height="28" />
        </span>
      </Menu.Button>
      <Menu.Items className="lang-list">
        {locales?.map(lang => (
          <Menu.Item key={lang}>
            {({ active }) => (
              <ListItem onClick={() => changeLanguage(lang)} active={active}>
                {t(lang)}
              </ListItem>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  )
}

export default LangSwitcher

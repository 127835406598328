import PropTypes from 'prop-types'
import { Text } from './UnderlinedText.styles'

function UnderlinedText({ text, number, color }) {
  return (
    <Text color={color}>
      {text}: <span>{number}</span>
    </Text>
  )
}

UnderlinedText.propTypes = {
  text: PropTypes.string,
  number: PropTypes.number,
  color: PropTypes.string,
}

export default UnderlinedText

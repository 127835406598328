import { Routes, Route } from 'react-router-dom'
import Dashboard from './Dashboard'
import CompleteProfile from './CompleteProfile'

export default function DashboardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="complete-profile" element={<CompleteProfile />} />
    </Routes>
  )
}

import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Player } from '@lottiefiles/react-lottie-player'
import { useFilters, usePagination, useTable } from 'react-table'
import empty from 'assets/lotties/empty.json'
//styles
import {
  EmptyList,
  Results,
  StyledTable,
  TabelBody,
  TabeleHeader,
  TabeleRow,
  TableData,
  TableFooter,
  TableHead,
  TableWrapper,
  Wrapper,
} from './Table.styles'
//components
import Status from './Status'
import Pagination from './Pagination'
import FilterHeader from './FilterHeader'
import DateFormatter from './DateFormatter'
import Loading from 'components/Loading'

function Table({
  activities,
  setActive,
  fidelityCurrency,
  fidelityUnit,
  isLoading,
}) {
  const [hotel, setHotel] = useState(null)
  const [status, setStatus] = useState(null)
  const { t } = useTranslation()
  const data = useMemo(() => (activities ? [...activities] : []), [activities])
  //get the hotels list
  const getHotels = array => {
    const hotels = []
    array?.forEach(item => {
      let itemExist = !!hotels.includes(item.hotelName)
      if (!itemExist) {
        hotels.push(item.hotelName)
      }
    })
    return hotels
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Hotel',
        accessor: 'hotelName',
      },
      {
        Header: 'check in',
        accessor: 'checkIn',
        Cell: ({ value }) => <DateFormatter date={value} />,
      },
      {
        Header: 'check out',
        accessor: 'checkOut',
        Cell: ({ value }) => <DateFormatter date={value} />,
      },
      {
        Header: 'status',
        accessor: 'status',
        Cell: ({ value }) => <Status value={value} />,
      },
      {
        Header: 'average price per night',
        accessor: 'averagePrice',
      },
      {
        Header: 'invoice num',
        accessor: 'invoiceNumber',
      },
    ],
    [],
  )

  const tableInstance = useTable({ columns, data }, useFilters, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    state,
    gotoPage,
    setFilter,
  } = tableInstance
  const { pageIndex } = state

  useEffect(() => setFilter('status', status), [status, setFilter])
  useEffect(() => setFilter('hotelName', hotel), [hotel, setFilter])

  return (
    <Wrapper>
      <FilterHeader
        hotels={getHotels(activities)}
        hotel={hotel}
        setHotel={setHotel}
        status={status}
        setStatus={setStatus}
        setActive={setActive}
        fidelityCurrency={fidelityCurrency}
        fidelityUnit={fidelityUnit}
      />
      {data?.length === 0 && isLoading && <Loading />}
      {data?.length > 0 && (
        <>
          <TableWrapper>
            <StyledTable {...getTableProps()}>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TabeleRow
                    isHeader={true}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <TabeleHeader {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </TabeleHeader>
                    ))}
                  </TabeleRow>
                ))}
              </TableHead>
              <TabelBody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <TabeleRow {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <TableData {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableData>
                      ))}
                    </TabeleRow>
                  )
                })}
              </TabelBody>
            </StyledTable>
          </TableWrapper>
          <TableFooter>
            {
              <Pagination
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                currentPage={pageIndex + 1}
                pageCount={pageCount}
                gotoPage={gotoPage}
              />
            }
            <Results>
              {`${t('showing')} ${page.length} ${t('of')} ${data.length}`}
            </Results>
          </TableFooter>
        </>
      )}
      {data.length === 0 && !isLoading && (
        <EmptyList>
          <p>{t('empty')}</p>
          <Player autoplay loop={true} src={empty} />
        </EmptyList>
      )}
    </Wrapper>
  )
}

Table.propTypes = {
  setActive: PropTypes.func,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      hotel: PropTypes.string,
      checkIn: PropTypes.string,
      checkOut: PropTypes.string,
      price: PropTypes.string,
      invoiceNum: PropTypes.string,
    }),
  ),
}

export default Table

import tw, { styled, theme } from 'twin.macro'

export const Wrapper = tw.div`
 flex gap-2.5 items-center justify-center 
`
export const StepNumber = styled.span`
  ${tw`text-white font-numbers font-semibold text-[5.313rem] leading-[6.625rem] flex-shrink-0`};
  -webkit-text-stroke: ${`1px ${theme`colors.brightBlue`}`};
  text-stroke: ${`1px ${theme`colors.brightBlue`}`};
`

export const Description = styled.div`
  ${tw`font-medium text-xl`}
  li {
    ${tw`list-disc`}
  }
`

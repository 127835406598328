import {  fetchit } from 'lib'

import {
    API_LOCATION
} from 'config/api'


export function getLocationById({ placeId }) {
    if(!placeId) return 
    return fetchit(API_LOCATION+'/'+placeId, null, 'GET')
}
import { QueryClient, QueryClientProvider } from 'react-query'
import RoutesComponent from './routes'

const App = () => {
  const queryClient = new QueryClient()

  return (
    <main>
      <QueryClientProvider client={queryClient}>
        <RoutesComponent />
      </QueryClientProvider>
    </main>
  )
}
export default App

import tw, { styled } from 'twin.macro'

export const Wrapper = tw.div`
 w-full flex flex-col justify-center items-start gap-8 mb-12
`

export const Header = tw.div`
 w-full flex flex-col gap-6  items-start lg:flex-row lg:justify-between lg:items-center pt-4 lg:pt-12  
`

export const Points = tw.div`
  flex justify-center items-center gap-4 text-base md:text-xl font-medium uppercase
`

export const Point = tw.div`
 flex justify-center items-center gap-4 md:gap-5
`

export const Buttons = tw.div`
  flex justify-center items-center gap-6 [&>button]:uppercase 
`

export const FilterButton = tw.button`
  bg-white inline-flex justify-center items-center gap-4 rounded-full px-6 py-3
      
  whitespace-nowrap flex-nowrap text-xl font-medium border border-[#64748b] capitalize 
`

export const FilterIcon = styled.span(({ isSelected }) => [
  tw`relative`,
  isSelected &&
    tw`after:content[""] after:absolute after:top-[-5px] after:right-[-5px]
  after:w-3 after:h-3 after:bg-brightBlue after:rounded-full`,
])

export const Filter = tw.div`
  w-full flex flex-col md:flex-row justify-start items-start md:items-center gap-8 pt-7 border-t border-[#DBDBDB] 
`

export const FilterColumn = tw.div`
   flex flex-col justify-center items-start gap-4
`

export const FilterTitle = styled.div`
  ${tw`
 w-full flex justify-between items-center text-xl font-medium capitalize
`}
  button {
    ${tw`text-darkPink text-base font-normal cursor-pointer`}
  }
`

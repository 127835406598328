import tw, { styled } from 'twin.macro'

export const Wrapper = tw.div`
  w-full 
`

export const Container = tw.div`
  w-full flex flex-col justify-center gap-12 md:gap-20 px-4 py-24 md:px-16 md:py-44 
`

export const Tabs = styled.div`
  ${tw`w-full flex justify-start items-center gap-20 border-b border-[#DBDBDB]
       uppercase text-xl font-medium`}
  button {
    ${tw`pb-[18px]`}
  }
  .active {
    ${tw`border-b-2 border-brightBlue`}
  }
`

export const Back = tw.button`
  flex items-center justify-center gap-2.5  text-brightBlue
`

export const Content = tw.div`
  w-full flex flex-col justify-center gap-4 md:gap-14 
`

import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { authorize } from './services'
import useUser from 'hooks/useUser'
import useUuid from 'hooks/useUuid'
import useTimeout from 'hooks/useTimeout'
import useInterval from 'hooks/useInterval'
import { REDIRECT_PATH } from 'config/paths'
import { AUTH_TIMEOUT, AUTH_INTERVAL } from 'config/auth'
import { Confiramtion, Container, Ellipse, Logo, Wrapper } from './Style'
import ellipse from 'assets/icons/ellipse.svg'
import inbox from 'assets/lotties/inbox.json'
import { Player } from '@lottiefiles/react-lottie-player'
import { useTranslation } from 'react-i18next'
import usePlace from 'hooks/usePlace'

export default function RefreshTokensComponent() {
  const selected = usePlace(state => state.selected)
  const user = useUser(state => state.user)
  const setUser = useUser(state => state.setUser)
  const uniqueId = useUuid(state => state.uuid)
  const params = useParams()
  const { t } = useTranslation()
  const { uuid, email } = params
  let navigate = useNavigate()
  const mutation = useMutation(() => authorize(email, uuid), {
    onSuccess(data) {
      data && data.user && setUser(data)
    },
  })

  useInterval(() => {
    if (!!uuid && uuid === uniqueId) {
      mutation.mutate()
    }
  }, AUTH_INTERVAL)

  useTimeout(() => {
    navigate(REDIRECT_PATH)
  }, AUTH_TIMEOUT)

  useEffect(() => {
    if (user && user.user) {
      navigate(REDIRECT_PATH)
    }
  }, [user, navigate])

  return (
    <Wrapper>
      <Ellipse src={ellipse} position="top" />
      <Ellipse src={ellipse} position="bottom" />
      <Container>
        {selected?.logo && <Logo src={selected?.logo} />}
        <Player
          style={{ width: '20rem', height: '15rem' }}
          autoplay
          loop={true}
          src={inbox}
        />
        <Confiramtion>
          <h1>please check your emails (🕵️ even spam)</h1>
          <p>you have 2 minutes before the session expires.</p>
        </Confiramtion>
        {/*<Resend>
          didn’t receive it? <button>resend</button>{' '}
         </Resend> */}
      </Container>
    </Wrapper>
  )
}

import PropTypes from 'prop-types'
//components
import Title from '../Title'
//styles
import { Subtitle, Wrapper } from './Header.styles'

function Header({ title, subtitle, isVertical }) {
  return (
    <Wrapper isVertical={isVertical}>
      <Title title={title} level={isVertical ? '1' : '2'} />
      <Subtitle>{subtitle}</Subtitle>
    </Wrapper>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isVertical: PropTypes.bool,
}

export default Header

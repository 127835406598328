import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
//Styles
import {
  Wrapper,
  Avatar,
  LeftFlex,
  Profile,
  RightFlex,
  Content,
  MobileContent,
  PanelContent,
} from './NavbarV2.styles'
//hooks
import useScrollDirection from 'hooks/useScrollDirection'
import useScrollPosition from 'hooks/useScrollPosition'
//Components
import { Popover, Transition } from '@headlessui/react'
import HotelsSelect from 'components/HotelsSelect'
import Notifications from 'components/Notifications'
import ProfileDropdown from 'components/ProfileDropdown'
//Icons
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/solid'
import avatarIcon from 'assets/icons/avatarIcon.svg'
import LangSwitcher from 'components/LangSwitcher'

function NavbarV2({ name, avatar, hotels, notifications }) {
  const [isHidden, setIsHidden] = useState(false)

  //change the navbar on scroll
  const scrollDirection = useScrollDirection()
  const scrollPosition = useScrollPosition()
  useEffect(() => {
    const delta = 20
    if (scrollDirection === 'down' && scrollPosition > delta) {
      setIsHidden(true)
    } else if (scrollDirection === 'up') {
      setIsHidden(false)
    }
  }, [scrollDirection, scrollPosition])
  return (
    <Wrapper isHidden={isHidden}>
      <Content>
        <LeftFlex>
          <HotelsSelect hotels={hotels} />
          <LangSwitcher />
        </LeftFlex>
        <RightFlex>
          {notifications && <Notifications notifications={notifications} />}
          <Profile>
            <ProfileDropdown name={name} />
            <Avatar>
              {' '}
              <img src={avatar ? avatar : avatarIcon} alt="avatar" />
            </Avatar>
          </Profile>
        </RightFlex>
      </Content>

      <Popover className="menu-popover v2">
        {({ open }) => (
          <>
            <MobileContent>
              <HotelsSelect hotels={hotels} />
              <RightFlex>
                <Popover.Button>
                  {open ? (
                    <XMarkIcon width={28} height={24} />
                  ) : (
                    <Bars3Icon width={28} height={24} />
                  )}
                </Popover.Button>
              </RightFlex>
            </MobileContent>
            <Transition
              className="menu-popover-panel v2"
              enter="enter"
              enterFrom="enterFrom"
              enterTo="enterTo"
              leave="leave"
              leaveFrom="leaveFrom"
              leaveTo="leaveTo"
            >
              <Popover.Panel>
                <PanelContent>
                  <Profile>
                    <ProfileDropdown name={name} />
                    <Avatar>
                      {' '}
                      <img src={avatar ? avatar : avatarIcon} alt="avatar" />
                    </Avatar>
                  </Profile>
                  <LangSwitcher />
                </PanelContent>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Wrapper>
  )
}

NavbarV2.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  hotels: HotelsSelect.propTypes.hotels,
  notifications: Notifications.propTypes.notifications,
}

export default NavbarV2

import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { loginOrRegister } from './services'
import { Container, Logo, Wrapper, Title, Form, Submit, Ellipse } from './Style'
import { AUTHORIZE_PATH, REDIRECT_PATH } from 'config/paths'
import useUser from 'hooks/useUser'
import useUuid from 'hooks/useUuid'
import usePlace from 'hooks/usePlace'
import { useTranslation } from 'react-i18next'
import Input from 'components/Input'
import ellipse from 'assets/icons/ellipse.svg'

export default function LoginComponent() {
  const user = useUser(state => state.user)
  const deleteUser = useUser(state => state.deleteUser)
  const selected = usePlace(state => state.selected)
  const uniqueId = useUuid(state => state.uuid)
  const setUuid = useUuid(state => state.setUuid)
  const [email, setEmail] = useState()
  const mutation = useMutation(() => loginOrRegister(email, uniqueId))
  const { t } = useTranslation()
  useEffect(() => {
    deleteUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!uniqueId) {
      setUuid(uuidv4())
    }
  }, [uniqueId, setUuid])

  const handleSubmit = e => {
    e.preventDefault()
    if (!!email && !!uniqueId) {
      mutation.mutate()
    } else {
      alert('Errors')
    }
  }

  const handleEmailInput = e => setEmail(e.target.value, uniqueId)

  if (!user?.user && mutation.isSuccess) {
    return <Navigate to={`${AUTHORIZE_PATH}/${uniqueId}/${email}`} replace />
  }

  if (!!user?.user?.email) {
    return <Navigate to={REDIRECT_PATH} replace />
  }

  return (
    <Wrapper>
      <Ellipse src={ellipse} position="top" />
      <Ellipse src={ellipse} position="bottom" />
      <Container>
        {selected?.logo && <Logo src={selected?.logo} />}
        <Title>
          {t('signInTo')} <span>{t('yourAccount')}</span>
        </Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={handleEmailInput}
          />
          <div>
            <Submit type="submit" disabled={!email}>
              {!mutation.isSuccess && !mutation.isError && !mutation.isLoading
                ? 'Continue'
                : null}
              {mutation.isSuccess ? 'check your email inbox' : null}
              {mutation.isError ? 'Try again' : null}
              {mutation.isLoading ? 'Loading...' : null}
            </Submit>

            {mutation.isError ? <>❌🤓 {mutation.error.message}</> : null}
          </div>
        </Form>
      </Container>
    </Wrapper>
  )
}

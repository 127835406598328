import { theme } from 'twin.macro'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
//components
import Navbar from 'components/Navbar'
import Title from 'components/Title'
import Button from 'components/Button'
import Header from 'components/Header'
import Card from 'components/Card'
//
import { GET_LOCATION_DETAILS } from 'config/queries'
import { getLocationById } from 'services/location'
//styles
import {
  Article,
  Section1,
  Section2,
  Grid,
  Section4,
  Columns,
  Column,
  Wrapper,
} from './Home.styles'
//icons and images
import blob from 'assets/icons/blob.svg'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { cards, steps1, steps2 } from './data'
import Banner from 'components/Banner'
import Steps from 'components/Steps'
import { useTranslation } from 'react-i18next'
import Footer from 'components/Footer'
import HeroImage from 'components/HeroImage'
import usePlace from 'hooks/usePlace'
import { useEffect } from 'react'

function Home() {
  const { t } = useTranslation()
  let { placeId } = useParams()
  const setSelected = usePlace(state => state.setSelected)
  const navigate = useNavigate()

  const { data: locationDetails } = useQuery(GET_LOCATION_DETAILS, () =>
    getLocationById({
      placeId,
    }),
  )

  useEffect(() => {
    if (locationDetails) {
      setSelected({ ...locationDetails, _id: placeId })
    }
  }, [locationDetails])
  const toLogin = () => {
    navigate('/auth/login')
  }

  return (
    <Wrapper>
      <Navbar toLogin={toLogin} logo={locationDetails?.logo} />
      <Section1>
        <img src={blob} alt="hotel room" />
        <Article>
          <span>{t('joinMessage')}</span>
          <Header
            isVertical={true}
            title={t('welcomeTitle')}
            subtitle={t('welcomeSubtitle')}
          />
          <Button
            onClick={toLogin}
            label={t('getStarted')}
            color={theme`colors.brightBlue`}
            isTransparent={true}
            Icon={ArrowRightIcon}
          />
        </Article>
      </Section1>
      <Section2 id="how-it-works">
        <Header title={t('howItWorks')} subtitle={t('howItWorksSubtitle')} />
        <Grid>
          {cards?.length > 0 &&
            cards.map(card => (
              <Card
                key={card.subtitle}
                Icon={card.Icon}
                subtitle={t(card.subtitle)}
                text={t(card.text)}
                color={card.color}
              />
            ))}
        </Grid>
      </Section2>
      <Section4 id="points">
        <Banner toLogin={toLogin} />
        <Columns>
          <Column withBorder={true}>
            <Title title={t('earn')} level="2" />
            <Steps withBorder={true} steps={steps1} />
          </Column>
          <Column>
            <Title title={t('spend')} level="2" />
            <Steps steps={steps2} />
          </Column>
        </Columns>
      </Section4>
      <HeroImage toLogin={toLogin} />
      <Footer logo={locationDetails?.logo} name={locationDetails?.name} />
    </Wrapper>
  )
}

export default Home

import { format, parseISO } from 'date-fns'
import PropTypes, { string } from 'prop-types'

function DateFormatter({ date }) {
  return <p>{format(parseISO(date), 'yyyy MMM, dd')}</p>
}

DateFormatter.propTypes = {
  date: string,
}

export default DateFormatter

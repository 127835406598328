import PropTypes from 'prop-types'
import { useState } from 'react'
//styled
import {
  Description,
  Wrapper,
  Header,
  Subtitle,
  HeaderImage,
} from './Card.styles'

function Card({ color, Icon, subtitle, text }) {
  const [withHeader, setWithHeader] = useState(false)
  return (
    <Wrapper
      onMouseLeave={() => setWithHeader(false)}
      onMouseEnter={() => setWithHeader(true)}
      withHeader={withHeader}
    >
      {!withHeader && <Icon color={color} />}
      {withHeader && (
        <Header color={color}>
          <HeaderImage>
            <Icon color="white" />
          </HeaderImage>
          <HeaderImage position="left">
            <Icon color="white" width={54} heigth={54} />
          </HeaderImage>
          <HeaderImage position="right">
            <Icon color="white" width={74} heigth={74} />
          </HeaderImage>
        </Header>
      )}
      <Subtitle color={color}>{subtitle}</Subtitle>
      {withHeader && <Description>{text}</Description>}
    </Wrapper>
  )
}

Card.propTypes = {
  color: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.func,
}

export default Card

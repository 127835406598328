import tw, { styled } from 'twin.macro'

export const Wrapper = styled.div(({ isHidden }) => [
  isHidden && tw`invisible`,
  tw`fixed z-20 w-full flex justify-between items-center gap-x-11 bg-brightBlue  px-4 py-1.5 
  lg:justify-center lg:px-16 lg:py-4`,
])

export const Content = tw.div`
 w-full hidden lg:flex justify-between items-center flex-1 
`

export const LeftFlex = tw.div`
 flex items-center justify-center gap-8
`
export const RightFlex = tw.div`
 flex items-center justify-center gap-11
`
export const Profile = tw.div`
 w-full flex items-center justify-center gap-6 px-4
`

export const Avatar = styled.div`
  ${tw`h-16 w-16 flex-shrink-0 flex justify-center items-center rounded-full border border-white bg-[#EAEAEA]`}
  img {
    ${tw`w-10 h-10 object-cover`}
  }
`

//Mobile
export const MobileContent = tw.div`
 w-full flex lg:hidden justify-between relative
`

export const PanelContent = tw.div`
 w-full flex flex-col gap-6 justify-center items-center py-5
`

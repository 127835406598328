import { Buttons, Wrapper } from './Banner.styles'
import Button from '../Button'
import { useTranslation } from 'react-i18next'

function Banner({ toLogin }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <p>{t('joinMessage2')}</p>
      <Buttons>
        <Button onClick={toLogin} label={t('signIn')} color="black" />
      </Buttons>
    </Wrapper>
  )
}

export default Banner

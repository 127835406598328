import tw from 'twin.macro'

export const ImageWrapper = tw.div`
 relative w-[95%] mx-auto  my-20 md:w-[90%] min-h-[500px] lg:my-32  rounded-2xl
 bg-center bg-no-repeat bg-cover 
`

export const Content = tw.div`
 md:w-[60%] absolute top-[17%] left-[9%]  right-[4%]
 flex flex-col items-start justify-center gap-4 lg:gap-8 
`

export const Subtitle = tw.p`
 text-base md:text-xl text-white first-letter:capitalize
`

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Logo, Rights, Social, Socials, Wrapper } from './Footer.styles'

function Footer({ logo, name, socials }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      {logo && <Logo src={logo} alt="logo" />}
      <Rights>
        {t('rights')} {name}
      </Rights>
      <Socials>
        <p>{t('followUs')}: </p>
        {socials?.length > 0 &&
          socials.map(social => (
            <Social href={social.link}>
              <img src={social.icon} />
            </Social>
          ))}
      </Socials>
    </Wrapper>
  )
}

Footer.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      icon: PropTypes.node,
    }),
  ),
}

export default Footer

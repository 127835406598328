import tw, { styled } from 'twin.macro'

export const Text = styled.p`
  ${tw`capitalize inline-flex gap-x-2.5 relative whitespace-nowrap text-base md:text-lg items-center`}
  span {
    ${tw`font-normal text-base md:text-4xl md:font-medium`}
  }
  &:after {
    content: '';
    ${tw`absolute rounded-full z-[-1] left-[-15%] right-[-7%] h-1 bottom-1
          md:bottom-2.5 md:h-1.5`}

    background-color: ${props => props.color};
  }
`

import PropTypes, { number } from 'prop-types'
//components
import Button from 'components/Button'
//styles
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { theme } from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { PageButtons, Wrapper } from './Pagination.styles'
function Pagination({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  currentPage,
  pageCount,
  gotoPage,
}) {
  const { t } = useTranslation()
  const range = (start, end) => {
    let length = end - start + 1
    //generate an array from start to the length
    return Array.from({ length }, (_, idx) => idx + start)
  }
  const getPagination = () => {
    //how any buttons we want to show.
    const siblings = 2
    const totalButtons = siblings + 5

    //case 1: total pages are less then the total buttons we want to show.
    if (pageCount < totalButtons) {
      return range(1, pageCount)
    }

    /*
     Calculate left and right siblings' index:
     if left sibling index is less than the first page we return the first page (1)
     and if right sibling index is more then the last page we return the last page (page count)
     */
    const leftSiblingIndex = Math.max(currentPage - siblings, 1)
    const rightSiblingIndex = Math.min(currentPage + siblings, pageCount)
    //show the left dots just when there is two numbers between the first page and the left siblings
    const shouldShowLeftDots = leftSiblingIndex >= 2
    //show the left dots just when there is two numbers between the last page and the right siblings
    const shouldShowRightDots = rightSiblingIndex <= pageCount - 1

    //Case 2: should show right dots
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = siblings + 1
      const leftRange = range(1, leftItemCount)

      return [...leftRange, '...', pageCount]
    }
    //case 3: should show only left dots
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = siblings + 1
      let rightRange = range(pageCount - rightItemCount + 1, pageCount)

      return [1, '...', ...rightRange]
    }

    //case 4 both sides should be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex + 1, rightSiblingIndex - 1)
      return [1, '...', ...middleRange, '...', pageCount]
    }
  }

  return (
    <Wrapper>
      <Button
        disabled={!canPreviousPage}
        isLeft={true}
        label={t('previous')}
        Icon={ChevronLeftIcon}
        color="black"
        isTransparent={true}
        onClick={previousPage}
      />
      <PageButtons>
        {getPagination()?.map(item => {
          return item === '...' ? (
            <span key={item}>{item}</span>
          ) : (
            <Button
              key={item}
              isRounded={true}
              label={item}
              color={currentPage === item ? 'black' : '#8D8D8D'}
              borderColor={
                currentPage === item ? theme`colors.brightBlue` : '#C8C8C8'
              }
              isTransparent={true}
              onClick={() => gotoPage(item - 1)}
            />
          )
        })}
      </PageButtons>
      <Button
        disabled={!canNextPage}
        label={t('next')}
        Icon={ChevronRightIcon}
        color="black"
        isTransparent={true}
        onClick={nextPage}
      />
    </Wrapper>
  )
}

Pagination.propTypes = {
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageCount: PropTypes.number,
}

export default Pagination

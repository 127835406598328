import { Popover } from '@headlessui/react'
import PropTypes from 'prop-types'
//styles
import {
  CountTag,
  Infos,
  NotifiactionsList,
  Notification,
  Title,
} from './Notifications.styles'
//Icons
import { BellIcon } from '@heroicons/react/24/solid'
import { t } from 'i18next'
import { format, isSameDay } from 'date-fns'

function Notifications({ notifications }) {
  const isSameDate = date => {
    const today = new Date()
    return isSameDay(today, date)
  }
  return (
    <Popover className="notifications">
      <Popover.Button style={{ position: 'relative' }}>
        <CountTag>{notifications?.length}</CountTag>
        <BellIcon fill="white" width={24} height={24} />
      </Popover.Button>
      <Popover.Panel className="notifications-panel">
        <Title>notifications ({notifications?.length})</Title>
        <NotifiactionsList>
          {notifications?.map(notification => (
            <Notification key={notification.id}>
              <img src={notification.icon} />
              <Infos>
                <p>{notification.title}</p>
                <span>
                  {t('invoiceNum')}: {notification.invoiceNum}
                </span>
              </Infos>
              <span>
                {isSameDate(notification.date)
                  ? format(notification.date, 'HH:mm a')
                  : format(notification.date, 'dd MMM')}
              </span>
            </Notification>
          ))}
        </NotifiactionsList>
      </Popover.Panel>
    </Popover>
  )
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      invoiceNum: PropTypes.string,
      date: PropTypes.date,
      Icon: PropTypes.func,
    }),
  ),
}

export default Notifications

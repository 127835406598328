import { createGlobalStyle } from 'styled-components'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const CustomStyles = createGlobalStyle`


html {
  scroll-behavior: smooth;
}
 body {
  ${tw`w-full font-primary`}
 }

 //Mobile Menu
 .menu-popover {
  ${tw` block lg:hidden`}
 }

 .menu-popover.v2 {
  ${tw`w-full flex justify-between items-center lg:hidden`}
 }

 .menu-popover-panel {
  ${tw`absolute right-0 left-0 top-16  bg-brightBlue pb-6 `}
 }
 .menu-popover-panel.v2 {
  top: 84px;
  
 }

 .enter {
  ${tw`transition duration-100 ease-out`}
 } 
 .enterFrom {
  ${tw`transform scale-95 opacity-0`}
 } 
 .enterTo {
  ${tw`transform scale-100 opacity-100`}
 }
 .leave {
  ${tw`transition duration-75 ease-out`}
 }
 .leaveFrom {
  ${tw`transform scale-100 opacity-100`}
 }
 .leaveTo {
  ${tw`transform scale-95 opacity-0`}
 }

 //Hotels group slect
 .hotels-button {
   ${tw`flex items-center justify-center gap-16 py-0.5 pl-1.5 pr-4
         border border-white rounded-2xl `}
   img {
    ${tw`w-16 h-16 object-contain`}
  }
 }

 .groups-list {
  ${tw`z-30 absolute top-[5.375rem] left-0 right-0 flex flex-col items-start
   max-h-72 overflow-y-auto bg-white shadow-panel py-8 pl-4 pr-2 rounded-xl`}
 }

 .group-option {
   ${tw`w-full flex justify-start items-center gap-8 cursor-pointer pb-1 pt-5 first:pt-0 px-4
     border-b border-[#E1E1E1] last:border-0 `}
 }

 //Profile Menu
 .profile-dropdown {
  ${tw`relative w-full flex justify-center items-center`}
 }
 .profile-button {
   ${tw`w-full flex justify-between lg:justify-center items-center gap-3.5`}
 }
 .profile-list {
   ${tw`z-30 absolute top-14 right-[-5.6rem] w-80 flex flex-col justify-center items-start
   bg-white shadow-panel px-9 py-5 rounded-2xl`}
 }
 .profile-item {
  ${tw`w-full flex justify-start gap-5 items-center`}
 }
 
 //Filter Select
 .filter-select {
  ${tw`relative text-xl capitalize`}
 }
 .filter-select-button {
 ${tw`flex justify-between items-center py-4 px-6 w-[19rem]
      border border-brightBlue rounded-2xl capitalize`}
 }

.not-selected {
  ${tw`text-[#A4A2A2] bg-[#F7F7F7] border-[#D6D6D6]`}
}
 .filter-list {
  ${tw` z-30 bg-white absolute left-0 right-0 top-20 shadow-panel rounded-xl p-4 pb-6
  flex flex-col gap-4`}
.filter-item {
    ${tw`w-full py-2 px-2.5 cursor-pointer hover:bg-[#EFEFEF] rounded-[8px]`}
  }

 }

//Notifications popup
.notifications {
  ${tw`relative`}
}
.notifications-panel {
  ${tw` absolute top-10 right-0 flex flex-col justify-center items-start gap-5
  w-[25.625rem] max-h-96 bg-white shadow-panel p-6 pr-2 rounded-xl`}
}
//Language menu
.lang-menu {
  ${tw`relative inline-flex justify-center items-center text-left w-full lg:max-w-max pt-6 lg:pt-0 border-t border-[rgba(255, 255, 255, 0.24)] lg:border-t-0`}
}
.lang-button {
 ${tw`inline-flex justify-center items-center gap-2 text-white text-xl font-medium uppercase`}
}
.lang-list {
  ${tw`absolute z-20 right-[50%] translate-x-[50%]  top-14 w-24 lg:translate-x-[0] lg:right-0 lg:left-0 lg:top-8 flex flex-col gap-2 py-4 justify-center items-center mt-2 origin-top-right 
  divide-y divide-gray-300 rounded-md bg-white  shadow-panel uppercase text-xl`}
}
//Modal
 .modal {
  ${tw`fixed left-0 top-0 w-full min-h-screen z-50 flex justify-center items-center`};
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
 }

 .modal-panel {
  ${tw`
    relative w-[90%] md:w-[60%] px-[6.25rem] pt-12 pb-10
    bg-white shadow-modal rounded-3xl
    `}
 }
 
 .swiper-slide {
  width: fit-content;
  padding: 8px 0
}
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles

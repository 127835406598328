import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Label, StyledInput } from './Input.Styles'

function Input({ type, placeholder, label, value, name, onChange, required }) {
  return (
    <Wrapper>
      {label && (
        <Label htmlFor={name}>
          {label} <span>*</span>
        </Label>
      )}
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        required={required}
      />
    </Wrapper>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Input

import { theme } from 'twin.macro'
import Join from 'icons/Join'
import Points from 'icons/Points'
import Prize from 'icons/Prize'
import { WifiIcon } from '@heroicons/react/24/solid'
export const cards = [
  {
    subtitle: 'joinCard',
    Icon: Join,
    color: theme`colors.brightBlue`,
    text: 'joinCardDescription',
  },
  {
    subtitle: 'earnCard',
    Icon: Points,
    color: theme`colors.yellow`,
    text: 'earnCardDescription',
  },
  {
    subtitle: 'spendCard',
    Icon: Prize,
    color: theme`colors.purple`,
    text: 'spendCardDescription',
  },
]

export const memberships = [
  {
    title: 'classic account',
    description:
      'Le client est automatiquement nommé Classic lorsqu’il s’inscrit auprogramme de fidélité et il a comme avantages :',
    bgColor: '#46D9B6',
    perks: [
      {
        title:
          '✅ 1000 points offerts pour la première réservation via le hotel',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
    ],
    label: 'classic',
  },
  {
    title: 'silver account',
    description:
      'Le client est automatiquement nommé Classic lorsqu’il s’inscrit auprogramme de fidélité et il a comme avantages :',
    bgColor: '#46D9B6',
    perks: [
      {
        title:
          '✅ 1000 points offerts pour la première réservation via le hotel',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
    ],
    label: 'silver',
  },
  {
    title: 'gold account',
    description:
      'Le client est automatiquement nommé Classic lorsqu’il s’inscrit auprogramme de fidélité et il a comme avantages :',
    bgColor: '#46D9B6',
    perks: [
      {
        title:
          '✅ 1000 points offerts pour la première réservation via le hotel',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
    ],
    label: 'gold',
  },
  {
    title: 'platinum account',
    description:
      'Le client est automatiquement nommé Classic lorsqu’il s’inscrit auprogramme de fidélité et il a comme avantages :',
    bgColor: '#46D9B6',
    perks: [
      {
        title:
          '✅ 1000 points offerts pour la première réservation via le hotel',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
      {
        title: '✅ Free Wifi',
        icon: WifiIcon,
      },
    ],
    label: 'platinum',
  },
]

export const steps1 = [
  {
    number: 1,
    description: 'earnStep1',
  },
  {
    number: 2,
    description: 'earnStep2',
  },
  {
    number: 3,
    description: 'earnStep3',
  },
  {
    number: 4,
    description: 'earnStep4',
  },
]

export const steps2 = [
  {
    number: 1,
    description: 'spendStep1',
  },
  {
    number: 2,
    description: 'spendStep2',
  },
  {
    number: 3,
    description: 'spendStep3',
  },
]

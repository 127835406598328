import React from 'react'
import PropTypes from 'prop-types'
import { Description, StepNumber, Wrapper } from './Step.styles'

function Step({ number, description }) {
  return (
    <Wrapper>
      <StepNumber>{number}</StepNumber>
      <Description>{description}</Description>
    </Wrapper>
  )
}

Step.propTypes = {
  number: PropTypes.number,
  description: PropTypes.string,
}

export default Step

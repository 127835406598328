import tw, { styled } from 'twin.macro'

export const Wrapper = styled.div(({ withHeader }) => [
  tw`
  flex flex-col justify-center gap-20 items-center 
   pb-[3.7rem] pt-20 max-w-[24rem] shadow-card rounded-xl
  `,
  withHeader && tw`pt-0 gap-6`,
])

export const Header = styled.div`
  ${tw`relative  w-full flex justify-center items-center min-h-[7.56rem] rounded-t-xl`};
  background-color: ${props => props.color};
`

export const HeaderImage = styled.div(({ position }) => [
  tw`absolute inline-block`,
  position === 'left' && tw`left-11 bottom-2 opacity-10`,
  position === 'right' && tw`right-6 top-2 opacity-10`,
])

export const Description = tw.p`
    first-letter:capitalize text-base pl-4 pr-5 pt-4
`

export const Subtitle = styled.h3`
  ${tw`font-normal text-xl relative pl-4 pr-5 uppercase`};
  //border bottom
  &:after {
    position: absolute;
    content: '';
    left: 16px;
    bottom: -16px;
    width: 15%;
    height: 2px;
    background-color: ${props => props.color};
  }
`

import tw, { styled } from 'twin.macro'

export const Wrapper = tw.div`
 w-full flex flex-col justify-center items-center gap-4
`
export const EmptyList = styled.div`
  ${tw`flex flex-col justify-center items-center gap-8`}

  p {
    ${tw`text-3xl font-medium first-letter:capitalize`}
  }
`

export const TableFooter = tw.div`
 w-full flex flex-col-reverse gap-4 md:flex-row justify-between items-center
`

export const Results = tw.p`
 text-[#8D8D8D] text-xl first-letter:capitalize
`
export const TableWrapper = tw.div`
 w-full overflow-x-auto whitespace-nowrap
`
export const StyledTable = styled.table`
  ${tw`w-full `};
  border-collapse: separate;
  border-spacing: 0;

  td {
    border: solid 1px #b8b8b8;
    border-style: none none none none;
    padding: 16px 45px;
    &:last-child {
      border-right: solid 1px #b8b8b8;
    }
  }
`

export const TableHead = tw.thead`
bg-[#F3F3F3]  text-lg font-medium uppercase  text-[#979797]  h-16 text-left border-collapse
`

export const TabeleRow = styled.tr`
  td:first-child {
    border-left-style: solid;
  }

  &:last-child {
    td {
      border-bottom: solid 1px #b8b8b8;
      padding-bottom: 32px;
      &:first-child {
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  &:first-child {
    td {
      border-top-style: solid;
      padding-top: 32px;
      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
`

export const TabeleHeader = tw.th`
 first:pl-14  last:pr-14 first:rounded-l-xl last:rounded-r-xl px-[45px]
`

export const TabelBody = styled.tbody`
  &:before {
    height: 1em;
    display: table-row;
    content: '';
  }
`

export const TableData = tw.td`
  first:pl-14  last:pr-14 py-4  
`

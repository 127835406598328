import { useEffect, useState } from 'react'
import { theme } from 'twin.macro'
//Styles
import { Buttons, Links, Logo, Nav, Wrapper } from './Navbar.styles'
//Components
import Button from '../Button'
import useScrollDirection from 'hooks/useScrollDirection'
import useScrollPosition from 'hooks/useScrollPosition'
import { useTranslation } from 'react-i18next'
import { Popover, Transition } from '@headlessui/react'
//Icons
import { UserIcon, XMarkIcon, Bars3Icon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import LangSwitcher from 'components/LangSwitcher'

function Navbar({ logo, toLogin }) {
  const [isHidden, setIsHidden] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const sections = [
    {
      label: 'howItWorks',
      link: '#how-it-works',
    },
    // {
    //   label: 'membership',
    //   link: '#membership',
    // },
    {
      label: 'navEarn',
      link: '#points',
    },
    {
      label: 'navSpend',
      link: '#points',
    },
  ]
  //change the navbar on scroll
  const scrollDirection = useScrollDirection()
  const scrollPosition = useScrollPosition()
  useEffect(() => {
    const delta = 20
    if (scrollDirection === 'down' && scrollPosition > delta) {
      setIsHidden(true)
    } else if (scrollDirection === 'up') {
      setIsHidden(false)
    }
  }, [scrollDirection, scrollPosition])

  return (
    <Wrapper isHidden={isHidden}>
      {logo && <Logo src={logo} alt="logo" />}
      <Nav>
        <Links>
          {sections?.map(section => (
            <li key={section.label}>
              <a href={section.link}>{t(section.label)}</a>
            </li>
          ))}
        </Links>
        <Buttons>
          <LangSwitcher />
          <Button
            label={t('myAccount')}
            color={theme`colors.brightBlue`}
            isTransparent={false}
            Icon={UserIcon}
            onClick={toLogin}
          />
        </Buttons>
      </Nav>
      <Popover className="menu-popover">
        {({ open }) => (
          <>
            <Popover.Button>
              {open ? (
                <XMarkIcon width={28} height={24} />
              ) : (
                <Bars3Icon width={28} height={24} />
              )}
            </Popover.Button>
            <Transition
              className="menu-popover-panel"
              enter="enter"
              enterFrom="enterFrom"
              enterTo="enterTo"
              leave="leave"
              leaveFrom="leaveFrom"
              leaveTo="leaveTo"
            >
              <Popover.Panel>
                <Links>
                  {sections?.map(section => (
                    <li key={section.label}>
                      <a href={section.link}>{t(section.label)}</a>
                    </li>
                  ))}
                </Links>
                <Buttons>
                  <Button
                    label={t('myAccount')}
                    color={theme`colors.brightBlue`}
                    isTransparent={false}
                    Icon={UserIcon}
                    onClick={toLogin}
                  />
                </Buttons>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Wrapper>
  )
}

export default Navbar

import tw, { styled } from 'twin.macro'

export const Wrapper = styled.div(({ isVertical }) => [
  tw` flex flex-col  w-full  justify-between items-start lg:items-center lg:flex-row `,
  isVertical && tw`flex-col lg:flex-col gap-8 lg:items-start`,
])

export const Subtitle = tw.p`
 lg:max-w-[30.3rem] text-xl first-letter:capitalize
`

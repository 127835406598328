import tw from 'twin.macro'

export const Wrapper = tw.div`
 w-full flex flex-col md:flex-row gap-6 justify-between items-center px-10  lg:px-16 py-[18px] 
 bg-yellow text-center text-black uppercase text-lg md:text-xl
`

export const Buttons = tw.div`
  flex justify-center items-center gap-4 flex-shrink-0 font-normal
`

import { fetchit } from 'lib'
import { API_FIDELITY } from 'config/api'

export function addActivity(data, accessToken) {
  return fetchit(API_FIDELITY, data, 'POST', accessToken)
}

export function getActivitiesByPlaceAndUserId(userId, placeId, accessToken) {
  if (!placeId || !userId) return

  return fetchit(
    API_FIDELITY + `/users/${userId}/${placeId}`,
    null,
    'GET',
    accessToken,
  )
}

export function getPlacesByUserId(userId, accessToken) {
  if (!userId) return
  return fetchit(API_FIDELITY + `/hotels/${userId}`, null, 'GET', accessToken)
}

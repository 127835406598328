import { Player } from '@lottiefiles/react-lottie-player'
import loading from 'assets/lotties/loading.json'

export default function Loading() {
  return (
    <>
      <div className="">
        <Player autoplay loop={true} src={loading} />
      </div>
    </>
  )
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import fr from './locales/fr.json'
import en from './locales/en.json'
import ar from './locales/ar.json'

export const locales = ['en', 'fr']
const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  fr: {
    translation: {
      ...fr,
    },
  },
  ar: {
    translation: {
      ...ar,
    },
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: locales,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

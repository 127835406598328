export const API_BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? 'https://demo-api-upoui.cloud.marrakech.io/v1/'
    : 'https://api.upoui.com/v1/'
export const UPLOAD_CDN = 'https://concierge.fra1.cdn.digitaloceanspaces.com/'
export const DEFAULT_DOC_URL =
  'https://fra1.digitaloceanspaces.com/team.marrakech.io/1662056408707.jpg'
export const API_AUTH_LOGIN = API_BASE_URL + 'auth/customer/login'
export const API_AUTH_LOGOUT = API_BASE_URL + 'auth/logout'
export const API_AUTH_REFRESH_TOKENS = API_BASE_URL + 'auth/refresh-tokens'
export const API_AUTH_AUTHORIZE = API_BASE_URL + 'auth/authorize'
export const API_HOTEL = API_BASE_URL + 'hotel'
export const API_LOCATION = API_BASE_URL + 'hotel/public'
export const API_FIDELITY = API_BASE_URL + 'fidelity'
export const API_UPDATE_USER = API_BASE_URL + 'users/completeprofile'

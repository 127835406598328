import tw from 'twin.macro'

export const Wrapper = tw.div`
 flex flex-col gap-4 items-start justify-center
`
export const Label = tw.label`
justify-center items-start gap-0.5 text-lg md:text-xl first-letter:capitalize [&>span]:text-darkPink
`

export const StyledInput = tw.input`
 w-full bg-[#F7F7F7] py-4 px-6  text-xl 
 border border-[#D6D6D6] rounded-xl 
`

import tw, { styled } from 'twin.macro'

export const Wrapper = tw.section`
  w-full flex items-center justify-between gap-16
`

export const UserInfos = styled.div`
  ${tw`flex flex-col text-2xl  md:text-[2.5rem] font-medium capitalize`}
  span {
    ${tw`text-2xl text-[#868686] first-letter:capitalize`}
  }
`

import tw, { styled, theme } from 'twin.macro'

export const Wrapper = tw.div`
   relative flex flex-col justify-center items-center min-h-screen w-full
`
export const Ellipse = styled.img(({ position }) => [
  tw`absolute z-[-10]`,
  position === 'top' && tw`top-0 right-0`,
  position === 'bottom' && tw`bottom-0 left-0 rotate-180`,
])
export const Container = tw.div`
  w-full px-7 flex flex-col gap-16 items-center justify-center md:w-2/3 md:max-w-[40rem]
`

export const Logo = tw.img`
  w-48 h-44 object-contain
`
//LOGIN
export const Title = styled.h2`
  ${tw`capitalize text-[1.75rem] lg:text-5xl font-bold`}
  span {
    ${tw`relative text-brightBlue`}
    &:before {
      content: '';
      position: absolute;
      bottom: -24px;
      left: 0;
      height: 8px;
      width: 40%;
      border-radius: 90px;
      background-color: ${theme`colors.brightBlue`};
    }
  }
`

export const Form = tw.form`
  flex flex-col w-full justify-center gap-6
  font-medium text-xl
`

export const Submit = tw.button`
w-full bg-brightBlue text-white inline-flex justify-center items-center  rounded-lg px-4 py-4
whitespace-nowrap flex-nowrap text-xl font-medium border-0 first-letter:capitalize
`

export const Error = styled.div`
  position: absolute;
  margin: 0 auto;
  width: 100%;
`
//AUTH
export const Confiramtion = styled.div`
  ${tw`flex flex-col justify-center items-center gap-8 mb-auto text-center `};
  h1 {
    ${tw`font-bold text-[2rem] first-letter:capitalize`}
  }

  p {
    ${tw`text-lg first-letter:capitalize`}
  }
`

export const Resend = styled.p`
  ${tw`text-lg first-letter:capitalize`}
  button {
    ${tw`text-brightBlue underline capitalize`}
  }
`

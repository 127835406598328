import PropTypes from 'prop-types'
import { status, Wrapper } from './Status.styles'

function Status({ value }) {
  return <Wrapper value={value}>{value}</Wrapper>
}

Status.propTypes = {
  value: PropTypes.oneOf(Object.keys(status)),
}

export default Status

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
//components
import DashboardHeader from 'components/DashboardHeader'
import Footer from 'components/Footer'
import Form from 'components/Form'
import NavbarV2 from 'components/NavbarV2'
import Table from 'components/Table'
import { toast } from 'react-toastify'
//config
import { COMPLETE_PATH } from 'config/paths'
import {
  GET_ACTIVITIES,
  GET_LOCATION_DETAILS,
  GET_PLACES,
} from 'config/queries'
import {
  addActivity,
  getActivitiesByPlaceAndUserId,
  getLocationById,
  getPlacesByUserId,
} from 'services'
//store
import usePlace from 'hooks/usePlace'
import useUser from 'hooks/useUser'
//styles
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { Back, Container, Content, Tabs, Wrapper } from './styels'

export default function Dashboard() {
  const queryClient = useQueryClient()
  const defaultState = useMemo(
    () => ({
      averagePrice: '',
      checkIn: '',
      checkOut: '',
      hotelName: '',
      invoiceNumber: '',
    }),
    [],
  )
  const [active, setActive] = useState('activities')
  const [activity, setActivity] = useState(defaultState)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useUser(state => state.user)
  const selected = usePlace(state => state.selected)

  //fetch all the places the user has added activities from (hotels visited)
  const { data: places } = useQuery([GET_PLACES, user.user._id], () =>
    getPlacesByUserId(user.user._id, user.access.token),
  )

  //we need only an array of hotels
  const hotels = places?.map(place => place.hotel)

  //when the user has not add any acivity yet we do not have a places array. So we create one that conatians the current one
  const initialPlace = useMemo(
    () => [{ name: selected?.name, logo: selected?.logo, _id: selected._id }],
    [selected],
  )

  const { data: activities, isLoading } = useQuery(
    [GET_ACTIVITIES, selected?._id],
    () =>
      getActivitiesByPlaceAndUserId(
        user?.user._id,
        selected?._id,
        user.access.token,
      ),
  )

  //Add activity logic
  const addActivityMutaion = useMutation(
    () =>
      addActivity(
        {
          ...activity,
          place: selected?._id,
          user: user.user._id,
        },
        user.access.token,
      ),
    {
      //we need the updated activities and the new hotels list when we add new activity
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ACTIVITIES)
        queryClient.invalidateQueries(GET_PLACES)
        setActivity(defaultState)
        toast.success(t('addSuccess'))
        setActive('activities')
      },
      onError: () => {
        toast.error(t('addError'))
      },
    },
  )
  const handleChange = e => {
    setActivity(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    addActivityMutaion.mutate()
  }

  //The user should not see the dashboard if he does not have a name yet
  useEffect(() => {
    if (user && !user.user.name && !user.user.firstname) {
      navigate(COMPLETE_PATH)
    }
    //eslint-disable-next-line
  }, [user])

  const { data: locationDetails } = useQuery(GET_LOCATION_DETAILS, () =>
    getLocationById({
      placeId: selected?._id,
    }),
  )

  return (
    <Wrapper>
      <NavbarV2
        name={user?.user.name + ' ' + user?.user.firstname}
        hotels={places?.length > 0 ? hotels : initialPlace}
      />
      <Container>
        <DashboardHeader
          points={activities?.totalPoints}
          userId={user?.user.shortId}
          userName={user?.user.name + ' ' + user?.user.firstname}
        />
        <Content>
          {active !== 'add-activity' && (
            <Tabs>
              <button
                onClick={() => setActive('activities')}
                className={active === 'activities' ? 'active' : ''}
              >
                {t('activities')}
              </button>
              {
                // <button
                //   onClick={() => setActive('redeem')}
                //   className={active === 'redeem' ? 'active' : ''}
                // >
                //   {t('redeem')}
                // </button>
              }
            </Tabs>
          )}
          {active === 'add-activity' && (
            <Tabs>
              <Back onClick={() => setActive('activities')}>
                <ChevronLeftIcon width={18} />
                {t('back')}
              </Back>
            </Tabs>
          )}
          {active === 'activities' && (
            <Table
              activities={activities?.results}
              setActive={setActive}
              fidelityCurrency={locationDetails?.fidelityCurrency}
              fidelityUnit={locationDetails?.fidelityUnit}
              isLoading={isLoading}
            />
          )}
          {active === 'add-activity' && (
            <Form
              onSubmit={handleSubmit}
              handleChange={handleChange}
              state={activity}
              isLoading={addActivityMutaion.isLoading}
            />
          )}
        </Content>
      </Container>
      <Footer />
    </Wrapper>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { UserInfos, Wrapper } from './DashboardHeader.stlyles'
import { t } from 'i18next'
import UnderlinedText from 'components/UnderlinedText'
import { theme } from 'twin.macro'

function DashboardHeader({ userName, userId, points }) {
  return (
    <Wrapper>
      <UserInfos>
        <p>
          {t('hello')} {userName},
        </p>
        <span>
          {t('user')} ID {userId}
        </span>
      </UserInfos>
      <UnderlinedText
        text={t('totalPoints')}
        number={points}
        color={theme`colors.lightGreen`}
      />
    </Wrapper>
  )
}

DashboardHeader.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
  points: PropTypes.number,
}

export default DashboardHeader

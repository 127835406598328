import tw, { styled } from 'twin.macro'

export const CountTag = tw.span`
  absolute top-0 right-[-12px]  w-3.5 h-3.5 rounded-full bg-darkPink
  flex justify-center items-center text-white text-[10px] font-medium
`

export const Title = tw.h2`
 text-2xl font-medium capitalize
`
export const NotifiactionsList = styled.ul`
  ${tw`
  w-full flex flex-col justify-center items-center gap-6  overflow-y-auto
  text-base font-medium letter-spacing[-0.02em]
  `}
  span {
    color: #838181;
  }
`

export const Notification = tw.li`
 w-full px-0.5 flex justify-center  gap-6 pb-6 
 border-b border-[#CCCCCC] last:border-0
`

export const Infos = tw.div`
  flex flex-col justify-center  mr-auto
`

import { useTranslation } from 'react-i18next'
import { Player } from '@lottiefiles/react-lottie-player'
import notFound from 'assets/lotties/notFoud.json'
import { Message, Wrapper } from './NotFound.styles'

export default function NotFound() {
  const { t } = useTranslation()
  return (
    <>
      <Wrapper>
        <Player autoplay loop={true} src={notFound} />
        <Message>{t('notFound')}</Message>
      </Wrapper>
    </>
  )
}

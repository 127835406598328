import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
//styles
import {
  Buttons,
  Filter,
  FilterButton,
  FilterColumn,
  FilterIcon,
  FilterTitle,
  Header,
  Point,
  Points,
  Wrapper,
} from './FilterHeader.styles'
import { theme } from 'twin.macro'
//components
import Button from 'components/Button'
import FilterSelects from 'components/FilterSelects'
//Icons
import money from 'assets/icons/money.svg'
import point from 'assets/icons/point.svg'
import filter from 'assets/icons/filter.svg'
import { PlusIcon } from '@heroicons/react/24/solid'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'

const allStatus = ['accepted', 'rejected', 'pending']
function FilterHeader({
  hotel,
  setHotel,
  hotels,
  status,
  setStatus,
  setActive,
  fidelityCurrency,
  fidelityUnit,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const isSelected = !!(status || hotel)

  return (
    <Wrapper>
      <Header>
        <Points>
          <Point>
            <img src={money} />
            <span>1 {fidelityCurrency}</span>
          </Point>
          <ArrowLongRightIcon width={60} height={30} />
          <Point>
            <img src={point} />
            <span>{fidelityUnit} point</span>
          </Point>
        </Points>
        <Buttons>
          <FilterButton onClick={() => setIsOpen(prev => !prev)}>
            filter{' '}
            <FilterIcon isSelected={isSelected}>
              <img src={filter} />
            </FilterIcon>
          </FilterButton>

          <Button
            label={t('addActivity')}
            Icon={PlusIcon}
            color="white"
            bgColor={theme`colors.brightBlue`}
            onClick={() => setActive('add-activity')}
          />
        </Buttons>
      </Header>
      {isOpen && (
        <Filter>
          <FilterColumn>
            <FilterTitle>
              <p>Hotel</p>
              {hotel ? (
                <button onClick={() => setHotel(null)}>{t('clear')}</button>
              ) : null}
            </FilterTitle>
            <FilterSelects
              options={hotels}
              selected={hotel}
              setSelected={setHotel}
              placeholder={t('selectHotel')}
            />
          </FilterColumn>
          <FilterColumn>
            <FilterTitle>
              <p>status</p>
              {status ? (
                <button onClick={() => setStatus(null)}>{t('clear')}</button>
              ) : null}
            </FilterTitle>
            <FilterSelects
              options={allStatus}
              selected={status}
              setSelected={setStatus}
              placeholder={t('accepted')}
            />
          </FilterColumn>
        </Filter>
      )}
    </Wrapper>
  )
}

FilterHeader.propTypes = {
  hotels: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  hotel: PropTypes.string,
  setHotel: PropTypes.func,
  setStatus: PropTypes.func,
}

export default FilterHeader

import { useTranslation } from 'react-i18next'
//components
import Button from 'components/Button'
import Title from 'components/Title'
//styles
import { theme } from 'twin.macro'
import { Content, ImageWrapper, Subtitle } from './HeroImage.styles'
//images and icons
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import hero from 'assets/img/hero.jpeg'
function HeroImage({ toLogin }) {
  const { t } = useTranslation()
  return (
    <ImageWrapper style={{ backgroundImage: `url(${hero})` }}>
      <Content>
        <Title title={t('heroTitle')} color="white" />
        <Subtitle>{t('heroSub')}</Subtitle>
        <Button
          onClick={toLogin}
          label={t('getStarted')}
          color={theme`colors.brightBlue`}
          Icon={ArrowRightIcon}
        />
      </Content>
    </ImageWrapper>
  )
}

export default HeroImage

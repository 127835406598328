import React, { Fragment } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ProtectedRoute from 'components/ProtectedRoute'
import AuthRoutes, { path as authPath } from './auth'
import HomeRoutes, { path as homePath } from './home'
import DashboardRoutes, { path as dashboardPath } from './dashboard'
import 'react-toastify/dist/ReactToastify.css'
import NotFound from 'components/NotFound'

export default function RoutesComponent() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path={authPath} element={<AuthRoutes />} />
            <Route exact path={homePath} element={<HomeRoutes />} />

            <Route element={<ProtectedRoute />}>
              {/**🔒 Protected routes }*/}
              <Route exact path={dashboardPath} element={<DashboardRoutes />} />

              {/**🔒 End of Protected routes */}
            </Route>

            <Route exact path={'*'} element={<NotFound />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  )
}

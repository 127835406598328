import PropTypes from 'prop-types'
import { Container, Wrapper } from './Steps.styles'
import Step from './Step'
import { ArrowLongDownIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

function Steps({ steps, withBorder }) {
  const lastIndex = steps.length - 1
  const { t } = useTranslation()
  return (
    <Wrapper withBorder={withBorder}>
      {steps?.length > 0 &&
        steps.map((step, index) => (
          <Container key={step.number}>
            <Step number={step.number} description={t(step.description)} />
            {index !== lastIndex && (
              <ArrowLongDownIcon color={'#EBEBEB'} width={50} height={40} />
            )}
          </Container>
        ))}
    </Wrapper>
  )
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      description: PropTypes.string,
    }),
  ),
  withBorder: PropTypes.bool,
}

export default Steps

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useUser from 'hooks/useUser'
import useCurrentPlace from 'hooks/usePlace'
import { AUTH_PATH, REDIRECT_PATH } from 'config/paths'

export default function ProtectedRoute({ redirectPath = AUTH_PATH, children }) {
  const location = useLocation()
  const user = useUser(state => state.user)
  const currentPlace = useCurrentPlace(state => state.selected)

  if (!user || !user.user) {
    return <Navigate to={redirectPath} replace />
  }

  if (!currentPlace && REDIRECT_PATH !== location.pathname) {
    return <Navigate to={REDIRECT_PATH} replace />
  }

  return children ? children : <Outlet />
}

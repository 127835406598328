import { Heading } from './Title.styles'
import PropTypes from 'prop-types'

function Title({ title, color, level = '1' }) {
  return (
    <Heading as={`h${level}`} color={color}>
      {title}
    </Heading>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Title

import tw from 'twin.macro'

export const Wrapper = tw.form`
 flex flex-col gap-16 justify-center items-center
`
export const Header = tw.div`
  w-full flex justify-between items-center
`

export const Title = tw.h2`
  text-2xl md:text-[2.5rem] font-medium capitalize
`

export const Grid = tw.div`
 w-full grid grid-cols-1 gap-8 
 md:grid-cols-2  md:gap-y-[4.25rem]
`

import tw, { styled } from 'twin.macro'

export const Wrapper = styled.div(({ isHidden }) => [
  isHidden && tw`invisible`,
  tw`fixed z-20 w-full flex justify-between items-center gap-x-11 bg-brightBlue text-white px-4 py-1.5 
  lg:justify-center lg:px-16 lg:py-4`,
])

export const Logo = tw.img`
 flex-none w-16 h-16 object-contain
`
export const Nav = tw.nav`
 hidden lg:flex justify-between items-center flex-1 
`
export const Links = styled.ul`
  ${tw`flex flex-col px-4 pb-24 pt-9 border-t border-b border-white justify-start items-start
   lg:flex-row lg:justify-center lg:items-center lg:p-0 lg:border-0  gap-10 text-xl whitespace-nowrap`}
  li {
    ${tw`first-letter:capitalize`}
  }
`

export const Buttons = tw.div`
 flex justify-center items-center gap-8 pt-7 lg:pt-0
`

import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { logout } from './services'
import useUser from 'hooks/useUser'
import useUuid from 'hooks/useUuid'
import usePlace from 'hooks/usePlace'
import { AUTH_PATH } from 'config/paths'

export default function RefreshTokensComponent() {
  const user = useUser(state => state.user)
  const uuid = useUuid(state => state.uuid)
  const deleteUser = useUser(state => state.deleteUser)
  const deleteSelected = usePlace(state => state.deleteSelected)
  const deleteUuid = useUuid(state => state.deleteUuid)

  function flushStorage() {
    deleteUser()
    deleteUuid()
    // deleteSelected()
  }

  useEffect(() => {
    if (user?.refresh?.token && uuid) {
      logout(user.refresh.token, uuid)
        .then(() => flushStorage())
        .catch(() => {
          flushStorage()
        })
    } else {
      flushStorage()
    }

    // eslint-disable-next-line
  }, [])

  return <Navigate to={AUTH_PATH} replace />
}

import tw, { styled } from 'twin.macro'

export const Wrapper = tw.footer`
 flex w-full justify-between items-center  py-6 
 bg-brightBlue text-white text-xl flex-wrap lg:flex-nowrap
`
export const Logo = tw.img`
 flex-none w-16 h-16 pl-4 object-contain
`

export const Rights = tw.p`
 order-last flex-basis[100%] text-center pt-3  border-t border-white mt-10 lg:mt-0
 lg:flex-none lg:p-0 lg:border-0
`

export const Socials = tw.div`
 flex gap-4 justify-center items-center pr-4 capitalize lg:order-last
`

export const Social = styled.a`
  ${tw`text-[0px] `}
  img {
    ${tw`w-7 h-6`}
  }
`

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Listbox } from '@headlessui/react'
import { Infos, Logo } from './HotelsSelect.styles'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import usePlace from 'hooks/usePlace'

function HotelsSelect({ hotels }) {
  const setPLace = usePlace(state => state.setSelected)
  const place = usePlace(state => state.selected)
  const [selected, setSelected] = useState(place)

  useEffect(() => {
    setPLace(selected)
  }, [selected])
  return (
    <Listbox
      as="div"
      style={{ position: 'relative' }}
      value={selected}
      onChange={setSelected}
    >
      <Listbox.Button className="hotels-button">
        <Infos>
          <img src={selected?.logo} />
          <p>{selected?.name}</p>
        </Infos>
        <span>
          <ChevronDownIcon color="white" width={14} height={14} />
        </span>
      </Listbox.Button>
      <Listbox.Options className="groups-list">
        {hotels?.length &&
          hotels.map(hotel => (
            <Listbox.Option
              className="group-option"
              key={hotel.name}
              value={hotel}
            >
              <Logo src={hotel?.logo} />
              <p>{hotel?.name}</p>
            </Listbox.Option>
          ))}
      </Listbox.Options>
    </Listbox>
  )
}

HotelsSelect.propTypes = {
  hotels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  ),
}

export default HotelsSelect

export const GET_HOTELS = 'getHotels'
export const GET_DEPARTMENTS = 'getDepartments'
export const GET_MEDIAS = 'getMedias'
export const GET_ROOMS = 'getRooms'
export const GET_CATEGORIES = 'getCategories'
export const GET_PRODUCTS = 'getproducts'
export const GET_ORDERS = 'getorders'
export const GET_RESERVATIONS = 'getReservations'
export const GET_EMAIL_TEMPLATES = 'getEmailTemplates'
export const GET_EMAIL_QUEUES = 'getEmailQueues'
export const GET_REVIEWS_STATS = 'getReviewsStats'
export const GET_PACKAGES = 'getPackages'
export const GET_SCHEDULES = 'getSchedules'
export const GET_WIDGETS = 'getWidgets'
export const GET_LOCATION_DETAILS = 'getLocationDetails'
export const GET_PLACES = 'getPlaces'
export const GET_ACTIVITIES = 'getActivities'

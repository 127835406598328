import PropTypes from 'prop-types'
import { StyledBtn, StyledIcon } from './Button.styles'

function Button({
  label,
  color,
  bgColor,
  borderColor,
  isTransparent,
  Icon,
  onClick,
  isLeft,
  isRounded,
  disabled,
}) {
  return (
    <StyledBtn
      isRounded={isRounded}
      disabled={disabled}
      onClick={onClick}
      color={color}
      bgColor={bgColor}
      borderColor={borderColor}
      isTransparent={isTransparent}
    >
      {label}
      {Icon && (
        <StyledIcon isLeft={isLeft}>
          <Icon width={22} height={22} />
        </StyledIcon>
      )}
    </StyledBtn>
  )
}

Button.propTypes = {
  color: PropTypes.string,
  borderColor: PropTypes.string,
  isTransparent: PropTypes.bool,
  isLeft: PropTypes.bool,
  onClick: PropTypes.func,
}
export default Button

import PropTypes from 'prop-types'
import { Listbox } from '@headlessui/react'
import { useState } from 'react'
import { Icon } from 'components/ProfileDropdown/ProfileDropdown.styles'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

function FilterSelects({ options, selected, setSelected, placeholder }) {
  return (
    <Listbox
      value={selected}
      onChange={setSelected}
      as="div"
      className="filter-select"
    >
      {({ open }) => (
        <>
          <Listbox.Button
            className={
              selected
                ? 'filter-select-button'
                : 'filter-select-button not-selected'
            }
          >
            {selected ? selected : placeholder}
            <Icon>
              {open ? (
                <ChevronUpIcon width={14} height={14} />
              ) : (
                <ChevronDownIcon width={14} height={14} />
              )}
            </Icon>
          </Listbox.Button>
          <Listbox.Options className="filter-list">
            {options?.length > 0 &&
              options.map(option => (
                <Listbox.Option
                  className="filter-item"
                  key={option}
                  value={option}
                >
                  {option}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  )
}

FilterSelects.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
}

export default FilterSelects
